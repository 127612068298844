@import "../../../../styles/variables.scss";
@import "../../../../styles/functions.scss";

.header-parent-new {
  height: 100%;
  border-bottom: 1px solid $header_border;
  box-shadow: 0 3px 3px rgba(66, 83, 102, 0.063);
  padding: 1rem 0;
  height: 80px;
  .logo-wrapper {
    width: 151px;
    .logo-container {
      padding-left: 30px;
    }
  }
  .custom-toggle-container {
    padding-left: 2rem;
  }

  .user-name-block {
    padding-right: 30px;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-left: 20%;
    .User-name-wrapper {
      cursor: pointer;
      margin-top: -2%;
      padding-left: 6%;
      &:hover {
        .user-name {
          color: #52a9c9;
        }
        .arrow-down {
          background: icon("icon-arrow-down-blue");
        }
      }
    }
    .bell-notification-wrapper {
      position: relative;
      cursor: pointer;
      z-index: 80;
      .icon-bell {
        width: 24px;
        height: 24px;
        margin-top: 28%;
      }
      .bell-notification-alert {
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: $col-notification;
        right: 2px;
        top: 5px;
        animation: bell-icon-notification 2s linear infinite;
      }
      .with-count {
        width: 24px;
        height: 24px;
        position: absolute;
        border-radius: 20px;
        background-color: #ff0000;
        margin-top: -147%;
        margin-left: 63%;
        color: white;
      }

      .msg-count {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 12px;
        font-family: "Open Sans bold";
      }
    }

    .hour-glass-wrapper {
      position: relative;
      z-index: 80;
      margin-top: 4%;
      margin-right: 2%;
      .hour-glass {
        width: 18px;
        height: 18px;
        animation: none;
        &.msg-sent {
          animation: hour-glass-rotate 2s cubic-bezier(0.8, 0, 0.2, 1) infinite;
        }
      }
    }

    .hide-logout-block {
      display: none;
    }
    .show-logout-block {
      display: block;
      border-radius: 0px 0px 12px 12px;
      padding: 12px 8px 11px 12px;
      background: $pure_white;
      position: absolute;
      right: 20px;
      box-shadow: 0px 3px 6px #00000033;
      min-width: 265px;
      top: 103%;
      z-index: 2;
      .tag-wrapper {
        padding-bottom: 16px;
        position: relative;
        .therapeutic-area {
          font-size: 11px;
          label {
            font-family: "Open Sans Medium" !important;
          }
          padding: 7px;
        }
        .country-heading {
          font-size: 14px;
          font-family: "Open Sans Semibold";
          color: #171616;
          padding-bottom: 6px;
        }
        .hide-countries {
          display: none;
        }
        .show-countries {
          display: flex;
          position: absolute;
          box-shadow: 0px 3px 6px #00000029;
          border: 1px solid #dedede;
          border-radius: 5px;
          z-index: 2;
          background: #ffffff 0% 0% no-repeat padding-box;
          right: 250px;
          .country-tag-class {
            font-size: 12px;
            padding: 7px 5px 5px 7px;
            label {
              font-family: "Open Sans Medium" !important;
            }
          }

          padding-left: 10px;
          padding-bottom: 10px;
          max-width: 146px;
        }
        .show-countries:after {
          content: " ";
          position: absolute;
          right: -11px;
          top: 10px;
          border-top: 5px solid transparent;
          border-right: none;
          border-left: 11px solid white;
          border-bottom: 4px solid transparent;
        }
        .show-countries:before {
          content: "";
          display: block;
          position: absolute;
          right: -11px;
          top: 10px;
          border-top: 5px solid transparent;
          border-right: none;
          border-left: 10px solid #00000029;
          border-bottom: 4px solid #c3272700;
        }
        .tag-country {
          padding-right: 8px;
          .country-tag-class {
            font-size: 12px;
            padding: 7px 5px 5px 7px;
            background: white;
            border: 1px solid #149ccc;
            label {
              font-family: "Open Sans Medium" !important;
            }
          }
          .country-tag-class:hover {
            box-shadow: 0px 3px 6px #00000029;
          }
          .more-class {
            color: #149ccc;
            font-size: 14px;
            font-family: "Open Sans Medium";
          }
        }
      }
      .logout-container {
        border-top: 1px solid $col-tag-boder;
        padding: 12px 0;
      }
      .logout-wrapper {
        display: flex;
        color: $pure_black;
        text-decoration: none;
        .icon-logout {
          width: 24px;
          height: 24px;
        }
        .logout-label {
          font-size: 14px;
          margin-left: 8px;
          font-family: "Open Sans Regular";
        }
      }
      .privacy-wrapper {
        display: flex;

        color: $pure_black;
        text-decoration: none;
        cursor: pointer;
        .icon-privacy {
          width: 24px;
          height: 24px;
        }
        .privacy-label {
          margin-left: 8px;
          font-family: "Open Sans Regular";
          font-size: 14px;
        }
        .icon-bayer-snow {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .notification-waiting-txt-wrapper {
    margin-top: -2%;
    margin-left: 8%;
    font-family: "Open Sans Regular";
    font-size: 10px;
    color: #409635;
    opacity: 1;
    width: 32%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 2px #00000029;
    border: 0.20000000298023224px solid #7ad06e;
    border-radius: 6px;
    opacity: 1;
    &.msg-sent {
      color: #c41414;
      border: 0.20000000298023224px solid #ff0015;
      width: 30%;
    }
    .notification-waiting-txt {
      padding-left: 5%;
    }
  }
  .name-initial-wrapper {
    text-decoration: none;
    color: $pure_black;
  }
  .user-name {
    text-transform: capitalize;
    font-size: 12px;
    font-weight: bold;
    font-family: "Open Sans Regular";
    color: #747b81;
  }
  .name-initials {
    width: 50px;
    height: 40px;
    background: icon("icon-user-inactive");
    margin-right: 7px;
  }
  .arrow-down {
    background: icon("icon-user-arrow");
    height: 24px;
    width: 24px;
  }
  .toggle-group {
    height: 37px;

    .MuiToggleButton-root {
      color: $pure_black;
      border: none;
      box-sizing: border-box;
      font-weight: 600;
      text-transform: unset;
      font-size: 12px;
      line-height: unset;
      height: 37px;
      padding: 0 15px;
      font-family: "Open Sans Medium";
      background-color: #d8dde6;
      border-radius: 5px 0px 0px 5px;
    }
    .MuiToggleButton-root.Mui-selected {
      font-weight: 600;
      background-color: $pure_white;
      color: $btn_blue;
      font-family: "Open Sans Medium";
      border: 1px solid #00bcff;
      border-radius: 0px 5px 5px 0px;
    }
  }
}

@keyframes bell-icon-notification {
  50% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes hour-glass-rotate {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.box {
  width: 500px;
  height: 100px;
  border: solid 5px #000;
  border-color: transparent transparent #000 transparent;
  border-radius: 0 0 240px 50%/60px;
}

@media only screen and (max-width: 768px) {
  .user-welcome {
    padding-left: 30px;
  }
}
