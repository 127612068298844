@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/functions.scss";
@import "../../../../../../styles/fonts.scss";

.select-filter-container {
  display: flex;
  height: 72px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #0000000a;
  border: 1px solid $col-tag-bg;
  border-radius: 8px;
  opacity: 1;
  
  .select-filter-wrapper {
    display: flex;
    gap: 8px;
    padding-left: 20px;
    &.select-filter-wrapper_with_gap{
      width: 69%;
    }
  }

  .filter-selected-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 28px;
    background: $white-smoke 0% 0% no-repeat padding-box;
    border: 1px solid #c1ccd0;
    border-radius: 4px;
    opacity: 1;
    height: 36px;
    &.filter-selected-wrapper_with_gap{
      gap:10px;
      height: 48px;
    }
  }
  .filter-selected-name {
    font-size: 14px;
    letter-spacing: 0px;
    color: $pure_black;
    opacity: 1;
    padding-left: 10px;
    padding-right: 10px;   
    &.ellipse-container {
      height: 40px;
      padding-right: 0px;
      word-break: break-all;    
    }       
  }
  
  .border-chevron-wrapper {
    border: 1px solid #24a3bc;
    padding: 8px;
    width: 8px;
    height: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 16px;
  }
}
@media only screen and (min-width: 1700px) {
  .select-filter-container {
    .select-filter-wrapper {
      .filter-selected-wrapper 
      .filter-selected-name{
        width: 90%;
        white-space: normal;
      }
    }
  }
}

