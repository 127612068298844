@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/functions.scss";
@import "../../../../../../styles/fonts.scss";

.filter-row-container {
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin-bottom: 16px;
  .filter-list-container {
    width: 100%;
    height: 140px;
    background: white;
    z-index: 10;
    position: absolute;
    top: 110%;
    left: 0;
    box-shadow: 1px 2px 6px #bebebe;
    border-radius: 4px;
  }
  .filter-name {
    font-family: Open Sans SemiBold;
    font-size: 14px;
    letter-spacing: 0px;
    color: $pure_black;
    opacity: 1;
    &.ellipse-container {
      font-family: "Open Sans Regular" !important;
      font-weight: normal;
      width: 260px;
      word-break: break-all;
    }
  }
  .filter-list-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
    height: 100%;
    padding-left: 20px;
    padding-right: 40px;
  }
  .mandatory-wrapper {
    display: flex;
    justify-content: flex-end;
    color: $col-mandatory-text;
    font-family: Open Sans SemiBold;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 12px;
    margin-right: 40px;
  }
  .jourey-info{
    color: #0c6aaa;
    display: flex;
    justify-content: flex-end;
    margin-top: 1%;
    .infrmtn-icon{
      width: 4%;
    }
  }
}

.dropdownbox-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .dropdownbox-multiselect {
    position: relative;
    width: 100%;

    .dropdown-options {
      overflow-y: auto;
      width: 100%;
      position: absolute;
      z-index: 1;
      border: 1px solid $dropdown_border;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000033;
      border-radius: 4px;
      opacity: 1;
      .options {
        max-height: 193px;
        overflow-y: auto;
      }
    }
    .dropdownmulti-option-item {
      padding: 16px;
      font-size: 14px;
      font-family: "Open Sans Regular" !important;
      display: flex;
      align-items: flex-end;
      label {
        color: $color_secondary;
        padding-left: 2px;
      }
      border-bottom: 1px solid $dropdown_border;
    }
    .dropdownmulti-option-item:hover {
      background: #f8f9fa 0% 0% no-repeat padding-box;
    }
  }
}
