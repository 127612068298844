@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";

.toast-container {
  position: absolute;
  background: $col-alert;
  align-items: center;
  height: 35px;
  box-sizing: border-box;
  z-index: 90;
  display: flex;
  justify-content: space-between;
  .alert-content {
    justify-content: center;
  }
  .toast-box {
    color: $pure_black;
    text-align: center;
    font-size: 12px;
    font-family: "Open Sans Regular";
  }
  .toast-cancel {
    text-align: right;
    padding-right: 15px;
    .cancel-icon {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
}
.top-space {
  top: 0;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}
.top-right {
  top: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}
.bottom-right {
  bottom: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}
.top-left {
  top: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}
.bottom-left {
  bottom: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}
@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.notification {
  width: auto !important;
  height: auto;
  position: absolute;
  top: 58px;
  right: 226px;
  box-shadow: 0px 3px 6px #00000029;
  min-height: 50px;
  max-width: 350px;
  padding: 16px 0 16px 16px;
  border-radius: 4px;
  &.green-notification {
    border-left: 3px solid $col-notification-green-border;
    background-color: $col-notification-green-bg;
  }
  &.blue-notification {
    border-left: 3px solid $col-notification-blue-border;
    background-color: $col-notification-blue-bg;
  }
  &.read-notification {
    border-left: 3px solid $pure_gray;
    background-color: $gray;
  }
  &.red-notification {
    border-left: 3px solid $col-notification-red-border;
    background-color: $col-notification-red-bg;
  }
}

.icon-read {
  filter: invert(48%) sepia(5%) saturate(21%) hue-rotate(325deg) brightness(95%)
    contrast(94%);
}

.icon-tick {
  background: icon("Icons-Check round");
  margin-right: 11px;
  width: 24px;
  height: 24px;
}
.icon-errortick {
  background: icon("icon-close-round");
  margin-right: 11px;
  width: 24px;
  height: 24px;
}
.icon-loading {
  background: icon("icon-loading");
  padding-right: 17px;
  margin-right: 17px;
  width: 24px;
  height: 24px;
}
.text-bold {
  font-weight: bold;
  font-family: "Open Sans Semibold";
  font-size: 16px;
  width: max-content;
}
.notification-content {
  align-self: center;
  .message-first-class {
    display: flex;
    align-items: baseline;
  }
  .message-second-class {
    display: flex;
    justify-content: space-between;
    color: $btn_blue;
    align-items: baseline;
    .time-class {
      font-family: "Open Sans Semibold";
      font-size: 10px !important;
      font-weight: bold;
      color: #1437cc;
    }
  }
  .view-detail-class {
    color: $btn_blue;
    text-decoration: underline;
    cursor: pointer;
  }
  .view-detail-errorclass {
    color: $btn_blue;
  }
}
.message-name {
  max-height: 100px;
  font-family: "Open Sans Regular";
  font-size: 14px;
  word-break: break-all;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}
