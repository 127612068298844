@import "../../styles/variables.scss";
.error {
  .error-icon {
    color: $alert_red;
    height: 2em;
    width: 2em;
  }

  .alert-body {
    display: flex;
    justify-content: flex-start;
    padding-top: 27px;
    padding-right: 54px;
    padding-left: 36px;
    padding-bottom: 18px;
  }
  .btn-align {
    .error-ok-button {
      width: 94px;
      height: 30px;
    }
    display: flex;
    justify-content: flex-end;
    padding-right: 35px;
    padding-bottom: 23px;
  }
  .alert-text {
    padding-left: 5px;
    padding-top: 5px;
    align-self: center;
  }
}
.error-popup-wrapper {
  width: 564px !important;
  min-height: 263px;
  max-height: 90vh;
  border-top: 3px solid #e20000;
  border-radius: 5px !important;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}
