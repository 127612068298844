@import "../../../../../styles/variables.scss";
@import "../../../../../styles/functions.scss";
@import "../../../../../styles/fonts.scss";

.group-wrapper {
  margin-bottom: 18px;

  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  .operator-button {
    width: 50px;
    padding: 2px;
    text-align: center;
    margin-left: 12px;
    letter-spacing: 0px;
    color: #454545;
    opacity: 1;
    font-family: Open Sans Semibold;
    font-size: 12px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #000000;
    border-radius: 3px;
    opacity: 1;
  }
  .group-title {
    font-family: Open Sans Semibold;
    font-size: 16px;
    line-height: 1.67;
    color: #656262;
    letter-spacing: 0px;
    opacity: 1;
    display: flex;
    align-items: center;
    padding-left: 10px;
    &.select-filter-text {
      font-size: 18px;
      color: #4d4a4a;
    }
  }
  .operator-select {
    font-family: Open Sans Semibold;
    font-size: 16px;
    line-height: 1.67;
    letter-spacing: 0px;
    color: #95a4ad;
    opacity: 1;
  }
  .group-header {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-top: 19px;
    margin-bottom: 10px;
    justify-content: space-between;
  }
  .operator-select-wrapper {
    display: flex;
    width: 100px;
    justify-content: space-between;
    align-items: center;
    padding-left: 6px;
    border-bottom: 2px dashed #2d9de2;
    .operator-group-select {
      font-family: Open Sans Semibold;
      font-size: 12px;
      letter-spacing: 0px;
      color: #2d9de2;
      opacity: 1;
      padding-left: 6px;
    }
    &.inactive {
      border-bottom: 2px dashed #e6e6e6;
      .operator-group-select {
        color: #95a4ad;
      }
    }
  }
  .disable-help-text {
    width: fit-content;
    margin-top: 10px;
    background-color: #e6f7ff;
    padding: 6px;
    border: 1px dashed #becfdd;
    color: #0c6aaa;
  }
  .operator-between-groups-container {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    right: 6%;
    cursor: pointer;

    .operator-between-groups-wrapper {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #d3f0fd;
      border-radius: 6px;
      opacity: 1;
      padding: 2px;
      bottom: 0px;
      position: absolute;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      min-width: 80px;
      .operator-between-groups-title {
        font-family: Open Sans Semibold;
        font-size: 16px;
        letter-spacing: 0px;
        color: #149ccc;
        opacity: 1;
      }
    }
  }
  .button-action-container {
    display: flex;
    justify-content: space-between;
    height: 68px;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    .new-group-title {
      font-family: Open Sans Semibold;
      font-size: 16px;
      letter-spacing: 0px;
      color: $secondary_btn_gray;
      opacity: 1;
      padding-left: 24px;
    }
    .line-between {
      border-left: 1px solid #cbe3ef;
    }
    .button-action-wrapper {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #cbe3ef;
      border-radius: 6px;
      opacity: 1;
      display: flex;
      gap: 6px;

      .button-action-with-icon-wrapper {
        display: flex;
        justify-content: center;
        gap: 2px;
        align-items: center;
        cursor: pointer;
      }
      .button-action-title {
        font-family: Open Sans Semibold;
        font-size: 14px;
        letter-spacing: 0px;
        color: #2d9de2;
        opacity: 1;
        padding: 8px;
      }
    }
  }
  .mccp_fill_up_info {
    font-family: Open Sans Semibold;
    color: #727272;
    font-size: 14px;
  }
}
.no-filter-title {
  font-family: Open Sans Semibold;
  font-size: 20px;
  letter-spacing: 0px;
  color: #445870;
  opacity: 1;
}

.no-filter-desc {
  font-family: Open Sans Semibold;
  font-size: 16px;
  letter-spacing: 0px;
  color: #445870;
  opacity: 1;
}

.count-hcps-wrapper {
  background: #d9e6f1 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  width: 78px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  .count-hcps-number {
    font-family: Open Sans Semibold;
    letter-spacing: 0px;
    color: #2b90a3;
    opacity: 1;
    font-size: 16px;
  }
}
