@import "../../../styles/variables.scss";
@import "../../../styles/fonts.scss";

.custom-dropzone-wrapper {
  .map-download {
    margin-top: 23px;
    margin-bottom: 10px;
    padding: 0px 114px 0 114px;
    align-items: center;
    cursor: pointer;
    p {
      font-family: "Open Sans bold" !important;
      font-size: 16px;
      line-height: 1.37;
      color: $col-download;
    }
  }
  .add-image {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }

  .dropzone-container {
    box-sizing: border-box;
    padding: 0px 114px 0 114px;
    &.error {
      padding-top: 33px;
    }
    .dropzone {
      width: 463px;
      padding-top: 35px;
      padding-bottom: 26px;
      background: #f5f5f5;
      border-radius: 6px;
      border: 1px dashed #1699c7;
      cursor: pointer;
      box-shadow: 0px 3px 6px #00000029;
      opacity: 1;
      p {
        color: #0d4e65;
        font-size: 15px;
        margin-top: 13px;
        text-align: center;
        font-weight: 600px !important;
        line-height: 20px;
      }

      .icon-drag-and-drop {
        height: 60px;
        width: 60px;
        margin: auto;
      }
      &.disabled {
        border: 1px dashed #5d5d5d;
        cursor: unset;
        box-shadow: none;
      }
    }
  }

  .upload-again {
    font-size: 12px;
    color: #ac1111;
    line-height: 17px;
    margin-bottom: 8px;
    font-weight: 600;
    margin-left: auto;
    margin-right: 6px;
  }
  .failed-text {
    color: #c42929;
    font-size: 10px;
    line-height: 12px;
    align-self: flex-end;
    text-align: right;
    font-weight: bold;
    margin-top: 7px;
    margin-right: 2px;
  }
  .listing-wrapper {
    flex-wrap: nowrap !important;
  }
  .file-listing {
    width: 100%;
    padding: 0 !important;
    margin-top: 20px;
    text-align: left;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    .icon-csv-file {
      height: 50px;
      width: 50px;
    }

    .file-title {
      width: 360px;
      overflow: hidden;
      color: #4f6064;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 4px;
      border-bottom: 1px dotted #bfbfbf;
      padding-bottom: 6px;
    }

    .file-size {
      color: #4d4d4d;
      padding-top: 5px;
      margin-left: 4px;
    }
  }
  .file-listing-error {
    width: 100%;
    padding: 0 114px;
    margin-top: 17px;
    text-align: left;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    .caution-icon {
      height: 24px;
      width: 24px;
      background: transparent;
      margin-left: auto;
      margin-top: 10px;
    }
    .file-title-error {
      width: 380px;
      color: #000000;
      overflow: hidden;
      padding-bottom: 8px;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-bottom: 5px solid #ac1111;
    }
    .file-size-error {
      color: #4d4d4d;
      padding-top: 7px;
    }
  }
  .icon-delete-wrapper {
    margin: auto;
  }
  .delete-icon {
    height: 24px;
    width: 24px;
    background: transparent;
    border: none;
    cursor: pointer;
    margin: auto;
    .icon-wrapper {
      height: 24px;
      width: 24px;
      background: transparent;
      border: none;
      cursor: pointer;
      margin: auto;
    }
  }

  .submit-uploaded {
    margin: 38px 24px 24px auto;
    &.error {
      margin-top: 34px;
    }
  }
  .submit-not-uploaded {
    margin: 86px 24px 24px auto;
  }

  .target-submit-uploaded {
    margin: 6px 24px 24px auto;

    &.error {
      margin-top: 34px;
    }
  }
  .target-submit-not-uploaded {
    margin: 12px 24px 24px auto;
  }
  .information-text-uploded {
    font-family: "Open Sans Semibold";
    font-size: 12px;
    color: #6e6e6e;
    padding: 15px 23px 0px 23px;
    border-width: thin;
    border-bottom: 1px solid #baaeae;
  }

  .information-text {
    font-family: "Open Sans Semibold";
    font-size: 12px;
    color: #6e6e6e;
    padding: 75px 23px 0px 23px;
    border-width: thin;
    border-bottom: 1px solid #baaeae;
  }

  .sending-info {
    margin-top: 60px;
    border-top: 1px dashed #baaeae;
    padding-top: 8px;
  }

  .sending-info-uploded {
    border-top: 1px dashed #baaeae;
    padding-top: 8px;
  }

  .confrntn-info {
    padding-bottom: 10px;
    margin-top: 8px;
    width: 100%;
  }
  .alert-box {
    width: 100%;
    align-items: center;
    padding: 22px 40px;
    margin-bottom: 17px;
    border: 1px solid #f0aeae;
    .alert-container {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }
}
