@import "../../../../../../styles/fonts.scss";
@import "../../../../../../styles/functions.scss";
@import "../../../../../../styles/variables.scss";

.sub-group-custom-wrapper {
  border: 1px solid #e6e6e6;
  padding: 12px;
  padding-top: 2px;
  box-shadow: 3px 3px 10px #00000022;
  width: 100%;
  border-radius: 8px;
  .cancel-icon {
    width: 24px;
    height: 24px;
    color: #c0cdd5;
    opacity: 1;
  }
}

.first-subgroup-container {
  border: 1px solid #e1e1e0;
  padding: 10px;
  box-shadow: 3px 3px 10px #00000022;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
}
