@import "../../../styles/variables.scss";
@import "../../../styles/fonts.scss";
@import "../../../styles/functions.scss";

.btn {
  padding: 16px;
  border: 1px solid;
  min-width: 150px;
  max-width: 328px;
  width: 100%;
  border-radius: 5px;
  font-family: "Open Sans Semibold";
  line-height: 1.3;
  font-size: 16px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  justify-content: center;
  cursor: pointer;

  .img-left-wrapper {
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }
  .img-right-wrapper {
    height: 20px;
    width: 20px;
    margin-left: 5px;
  }
  .icon {
    height: 18px;
    width: 18px;
    margin-left: 4px;

    &.icon-right-arrow-white {
      background: icon("icon-right-arrow-white", "svg");
    }
    &.icon-right-arrow-gray {
      background: icon("icon-right-arrow-gray", "svg");
    }
    &.icon-right-arrow-blue {
      background: icon("icon-right-arrow-blue", "svg");
    }
  }

  &.small {
    padding: 8px 16px;
    min-width: 100px;
    width: auto;
  }
  &.auto {
    padding: 10px 15px;
    width: auto;
  }

  &.primary {
    color: $pure_white;
    background-color: $btn_blue;
    border-color: $btn_blue;
    &.disabled {
      color: $primary_btn_gray;
      background-color: $primary_border_gray;
      border-color: $primary_border_gray;
      pointer-events: none;
    }
  }

  &.secondary {
    color: $btn_blue;
    background-color: $pure_white;
    border-color: $btn_blue;
    &.disabled {
      color: $secondary_btn_gray;
      border-color: $secondary_border_gray;
      pointer-events: none;
    }
  }

  &.tertiary {
    color: $btn_blue;
    background-color: transparent;
    border-color: transparent;
    &.disabled {
      color: $tertiary_btn_gray;
      pointer-events: none;
    }
  }
  &.btn-simulate {
    width: 172.89px;
  }
  &.date-range-close-btn {
    border: none;
    font-size: 14px;
    text-decoration: none;
    color: #103a78;
    background: none;
    font-weight: 700;
  }
  &.comment-save-btn {
    font-weight: 600;
  }
}
