@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/functions.scss";
@import "../../../../../../styles/fonts.scss";

.simple-filter-row-container {
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin-bottom: 16px;
  &.half-width-container {
    width: 47%;
  }

  .simple-filter-list-wrapper {
    display: flex;
    gap: 16px;
    flex-direction: column;
  }
  .simple-filter-name {
    font-family: Open Sans Semibold;
    font-size: 14px;
    letter-spacing: 0px;
    color: $pure_black;
    opacity: 1;
  }
  .mandatory {
    font-family: Open Sans Semibold;
    color: $col-mandatory-text;
    font-size: 14px;
    line-height: 14px;
  }
  .recommended {
    font-family: Open Sans Semibold;
    display: flex;
    justify-content: flex-end;
    margin-bottom: -12px;
  }
}
