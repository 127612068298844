@import "../../../styles/variables.scss";
.checkbox-container {
  position: relative;
  input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    display: none;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    height: 16px;
    width: 16px;
    border: 1px solid $checkbox_border_color;
    border-radius: 2px;
    opacity: 1;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background: $btn_blue 0% 0% no-repeat padding-box;
    border: 1px solid $btn_blue;
    border-radius: 2px;
    opacity: 1;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid $pure_white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
