@import "./styles/variables.scss";
@import "./styles/fonts.scss";
@import "./styles/functions.scss";
body {
  margin: 0;
  font-family: "Open Sans Regular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $body_background;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}
h1 {
  font-family: "Open Sans semibold";
}
h2,
h3,
h4 {
  font-family: "Open Sans Medium";
}
p {
  font-family: "Open Sans Regular" !important;
}
label {
  font-family: "Open Sans Regular";
}
h1 {
  font-size: $fontsize_h1;
}
h2 {
  font-size: $fontsize_h2;
}
h3 {
  font-size: $fontsize_h3;
  color: $heading_text;
}
h4 {
  font-size: $fontsize_h4;
  font-weight: normal;
}

.center {
  text-align: center;
}
.no-data {
  padding-top: 5px;
}

.custom-scroll {
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $color_secondary;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $tile_border;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $menu_inactive;
  }
}

.form-field-error-msg {
  height: 12px;
  font-size: 12px;
  color: $text-red;
}
//....................
//Date range pop up
.date-range {
  width: 100%;
  .rdrDefinedRangesWrapper {
    display: none;
  }

  .rdrDateRangeWrapper {
    width: 100%;
  }
  .rdrMonth {
    width: 50%;
  }
  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrDateDisplayItemActive {
    color: $light_blue !important;
  }
}
//dropdown
.dropdown-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .disable-parent-box {
    pointer-events: none;
    border-radius: 2px !important;
    background-color: $dropdowndown_inactive_background;
    border-color: $input_inactive_bd_gray;
  }
  .dropdown-singleselect {
    position: relative;
    width: 100%;
    .dropdown-input-field {
      height: 40px;
      width: 100%;
      border: 1px solid $dropdown_border;
      border-radius: 4px;

      .dropdown-button {
        display: flex;
        padding: 10px 10px;
        justify-content: space-between;
        label {
          font-size: 14px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .disable-dropdown {
        pointer-events: none;
        background-color: $dropdowndown_inactive_background;
        border-color: $input_inactive_bd_gray;
      }

      .arrow-up {
        transform: rotate(180deg);
      }
      &:active,
      &:focus {
        border: 1px solid $btn_blue;
      }
      &:hover {
        border: 1px solid $btn_blue;

        .dropdown-button img {
          filter: invert(43%) sepia(91%) saturate(671%) hue-rotate(156deg)
            brightness(96%) contrast(86%);
        }
      }
    }
    .dropdown-input-field.active {
      border: 1px solid $btn_blue;
    }
    .dropdown-options {
      max-height: 250px;
      overflow-y: auto;
      width: 100%;
      position: absolute;
      z-index: 1;
      border: 1px solid $dropdown_border;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000033;
      border-radius: 4px;
      opacity: 1;
      &.up {
        bottom: 42px;
      }
    }
    .dropdown-option-item {
      font-size: 14px;
      font-family: "Open Sans Regular" !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .tick-icon {
        background: icon("icon-green-tick", "svg");
        height: 24px;
        width: 24px;
        margin-right: 16px;
      }
      label {
        padding: 16px 0px 16px 16px;
        color: $color_secondary;
      }
      border-bottom: 1px solid $dropdown_border;
    }
    .dropdown-option-item:hover {
      background: #f8f9fa 0% 0% no-repeat padding-box;
    }
  }
}
//dropdown-grouped
.grouped-dropdown-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  label {
    color: $color_secondary;
  }

  select {
    width: 100%;
    padding: 10px 5px;
    border: 1px solid $color_secondary;
    border-radius: 8px;
  }
}
//checkbox
.checkbox-parent {
  padding: 10px 0;
  &.search-filter {
    .checkbox-header {
      font-size: 16px !important;
      color: $color_secondary;
      font-family: "Segoe UI Regular";
      font-weight: normal;
    }
  }
  .checkbox-header-row {
    font-size: $btn_font_size;
    color: $pure_black;
    margin-bottom: 4px;
  }
  .checkbox-header {
    font-size: $checkbox_heading_size;
    color: $pure_black;
    margin-bottom: 7px;
    font-weight: bold;
  }
  .collapse {
    max-height: 93px;
    overflow: hidden;
  }
  .search-box {
    margin: auto 15px;
    position: relative;
    width: 20%;
    .search-input {
      width: 100%;
      border-radius: 4px;
      border: 1px solid $checkbox-color;
      padding: 5px;
      padding-right: 28px;
    }
    &::after {
      content: "";
      height: 20px;
      width: 20px;
      position: absolute;
      background: icon("icon-search-grey");
      background-size: 17px;
      right: -30px;
      top: 5px;
    }
  }
  .checkbox-container {
    padding-left: 60px;
  }
  .checkItem {
    margin: 5px 0;
  }
  .lbl {
    font-size: $checkbox_font-size;
    color: $checkbox-color;
    padding: 0 8px;
    border: 1px solid $checkbox-color;
    border-radius: 4px;
    margin-right: 6px;
    cursor: pointer;
    border-radius: 4px;
  }
  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"]:checked + .lbl {
    background-color: $light_blue;
    color: $pure_white;
    cursor: pointer;
  }
  &.horizontal-alignment {
    flex-direction: row;
    align-items: center;
    .label-container {
      width: auto;
      align-items: center;
      padding-right: 20px;
    }
    .element-container {
      width: auto;
      align-items: center;
    }
  }
  .more-less-button {
    padding: 10px 0;
    p {
      font-size: $checkbox_font-size;
      color: $menu_inactive;
      cursor: pointer;
    }
  }
}
//date-picker
.datepicker {
  padding: 0 20px;
}
.MuiPickersDay-daySelected,
.MuiPickersDay-daySelected:hover {
  background-color: $light_blue;
}
.MuiFormControl-marginNormal {
  margin-top: -5px;
  margin-bottom: 0;
}
.MuiSvgIcon-root {
  width: 20px;
  height: 20px;
}
.MuiInput-root {
  font-size: 14px;
}
.search-filter {
  .datepick-header {
    font-size: $btn_font_size !important;
    color: $color_secondary;
    font-family: "Segoe UI Regular";
    font-weight: normal;
  }
  .datepicker {
    padding: 0;
    color: $checkbox-color;
  }
  .MuiInputBase-input {
    color: $checkbox-color;
  }
}
//autosuggest
.autosuggest-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .autosuggest-parent {
    .autosuggest {
      width: 100% !important;

      .MuiAutocomplete-inputRoot {
        border: 1px solid $color_secondary;
        border-radius: 8px;

        input {
          padding: 0;
        }

        fieldset {
          border: none;
        }

        button {
          display: none;
        }
      }
    }
  }

  label {
    color: $color_secondary;
  }
}
.search-field-class {
  width: -webkit-fill-available !important;

  .MuiAutocomplete-inputRoot {
    padding: 7px 0px 7px 0px !important;
    height: 33px;

    .MuiAutocomplete-popupIndicator {
      transform: none !important;
      -webkit-transform: none;
    }
    .MuiAutocomplete-input {
      font-family: "Open Sans Regular" !important;
      line-height: 19px !important;
      padding: 0px 0px 7px 10px !important;
      height: auto !important;
      &::placeholder {
        color: #6e757c !important;
        font-size: 14px;
      }
    }
  }
}
.nooption-class {
  height: 24px;
  font-family: "Open Sans Regular";
  padding: 10px 0px 10px 15px;
  font-size: 16px;
  color: $dropdownitem_color;
}
//auto suggest multiple
.autosuggest-multiple-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .purpose-lbl {
    font-family: "Open Sans italic";
  }

  .infrmtn-icon-cls {
    margin-left: 3%;
    .infrmtn-icon {
      width: 24px;
      height: 24px;
    }
  }
  .MuiAutocomplete-popupIndicator {
    transform: none;
  }
  .css-19idom {
    margin-bottom: 10px;
  }

  .autosuggest-parent {
    width: 100%;

    &.single-select {
      margin-top: 2%;
    }
    .disable-dropdown {
      min-height: 24px;
      pointer-events: none;
      background-color: $dropdowndown_inactive_background;
      border-color: $input_inactive_bd_gray;
    }
    .multiselectdropdown-button {
      padding: 9px;
      border: 1px solid #dfe2e5;
      border-radius: 4px;
      font-size: 14px;
      justify-content: space-between;
      align-items: center;
      display: flex;

      &.dropdwn-padding-custom {
        padding: 5px 7px 3px 9px !important;
      }

      .label-section {
        width: calc(100% - 24px);
        max-width: calc(100% - 24px);
        justify-content: space-between;
        display: flex;
        width: 100%;
      }
      .first-label {
        max-height: 19px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .more-class {
        color: #149ccc;
        font-family: "Open Sans Semibold";
      }
      .arrow-up {
        transform: rotate(180deg);
      }
      &:active,
      &:focus {
        border: 1px solid $btn_blue;
      }
      &:hover {
        border: 1px solid $btn_blue;

        img {
          filter: invert(43%) sepia(91%) saturate(671%) hue-rotate(156deg)
            brightness(96%) contrast(86%);
        }
      }
    }
    .MuiChip-label {
      font-family: "Open Sans Regular";
      font-size: 14px;
      line-height: 1.35;
    }
    .autosuggest-multiple {
      width: 100% !important;
      .MuiAutocomplete-popupIndicator {
        transform: none;
      }

      .MuiTextField-root {
        .MuiAutocomplete-inputRoot {
          border: 1px solid $input_border_gray;
          border-radius: 8px;
          max-height: 101px;
          height: auto;
          overflow-y: overlay;
          overflow-x: hidden;
          padding: 10px 5px 10px 10px;
          &.Mui-focused {
            border: 1px solid $btn_blue;
            img {
              filter: invert(43%) sepia(91%) saturate(671%) hue-rotate(156deg)
                brightness(96%) contrast(86%);
            }
          }
          &::-webkit-scrollbar {
            width: 5px;
            pointer-events: none;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px $color_secondary;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: $tile_border;
            border-radius: 10px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: $menu_inactive;
          }

          .MuiAutocomplete-endAdornment {
            .MuiAutocomplete-clearIndicator {
              display: none;
            }
          }

          input {
            padding: 0;
            height: 33px;
            &::placeholder {
              width: 75%;
              opacity: 1;
              font-family: "Open Sans Regular";
              font-size: 14px;
              line-height: 1.35;
              letter-spacing: 0px;
            }
          }

          fieldset {
            border: none;
          }
        }
      }
    }
  }
}
//Radio
.radio-parent {
  padding: 10px 0;
  .radio-header-row {
    font-size: $btn_font_size;
    color: $pure_black;
    margin-bottom: 4px;
  }
  .radio-header {
    font-size: $checkbox_heading_size;
    color: $pure_black;
    margin-bottom: 7px;
  }
  .search-box {
    margin: auto 15px;
    position: relative;
    width: 20%;
    .search-input {
      width: 100%;
      border-radius: 4px;
      border: 1px solid $checkbox-color;
      padding: 5px;
      padding-right: 28px;
    }
    &::after {
      content: "";
      height: 20px;
      width: 20px;
      position: absolute;
      background: icon("icon-search-grey");
      background-size: 17px;
      right: -30px;
      top: 5px;
    }
  }
  .radio-container {
    padding-left: 60px;
  }
  .checkItem {
    margin: 5px 0;
  }
  .lbl {
    font-size: $checkbox_font-size;
    color: $checkbox-color;
    padding: 0 15px;
    border: 1px solid $checkbox-color;
    border-radius: 4px;
    margin-right: 30px;
    cursor: pointer;
    border-radius: 4px;
    min-width: 50px;
  }
  input[type="radio"] {
    display: none;
  }
  input[type="radio"]:checked + .lbl {
    background-color: $light_blue;
    color: $pure_white;
    cursor: pointer;
  }

  &.horizontal-alignment {
    flex-direction: row;
    align-items: center;
    .label-container {
      width: auto;
      align-items: center;
      padding-right: 20px;
    }
    .element-container {
      width: auto;
      align-items: center;
    }
  }
}

//popup
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($pure_black, 0.7);
  z-index: 99;

  .popup-wrapper {
    background: $pure_white;
    transition: all 0.8s;
    border-radius: 6px;
    overflow-y: auto;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    &.save-target-popup {
      max-width: 671px;
    }
    &.lg {
      width: 90%;
    }
    &.md {
      width: 55%;
    }
    &.sm {
      width: 40%;
    }
    &.xs {
      width: 20%;
    }

    .popup-header {
      background: $white-smoke 0% 0% no-repeat padding-box;
      height: 98px;
      padding: 24px;
      box-sizing: border-box;
      font-weight: 600;
      display: flex;
      direction: row;
      align-items: center;

      .img-logo-container {
        width: 50px;
        height: 50px;
        margin-right: 12px;
      }
      .error-popup {
        width: 35px;
        height: 35px;
        margin-right: 12px;
        padding: 15px;
        padding-left: 6px;
      }

      .popup-title p {
        font-family: "Open Sans Semibold" !important;
        font-size: 24px;
        line-height: 33px;
        letter-spacing: -1px;
        color: #292727;
        opacity: 1;
        text-align: left;
      }
      .close-icon {
        height: 24px;
        width: 24px;
        margin-left: auto;
        margin-bottom: auto;

        cursor: pointer;
      }
    }
    &.user-file-popup {
      .popup-header {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
    &.no-crm-id {
      width: 55%;
    }
    .popup-header-no-icon {
      height: 90px;
      padding: 30px;
    }
    .close {
      height: 24px;
      width: 24px;
      float: right;
      margin-right: 20px;
      margin-top: 20px;
      cursor: pointer;
    }
    .popup-body {
      padding: 0;
      box-sizing: border-box;
      .top-grid {
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.custom {
          margin: 10px;
        }
        .results {
          font-weight: bold;
          font-size: 20px;
          line-height: 1.375;
          color: #292727;
        }
        .hcps {
          font-weight: bold;
          font-size: 24px;
          line-height: 1.35;
          color: $pure_black;
        }
        .btns-wrapper {
          display: flex;
          align-items: center;
          .button-wrapper {
            margin-right: 20px;
          }
        }
      }
      .button-group {
        display: flex;
        float: right;
        padding-bottom: 15px;

        .btn {
          padding: 6px 15px;
        }
        .btn-clear {
          margin-right: 10px;
          color: $pure_gray;
          border: none;
          background-color: $pure_white;
        }
      }
      .no-data-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 70px;
        padding-bottom: 70px;
      }
      .popup-footer-container {
        padding: 16px;
        background: #fafafa 0% 0% no-repeat padding-box;
        box-shadow: 0px -3px 6px #00000029;
        border-radius: 0px 0px 28px 28px;
        &.no-items {
          height: 70px;
        }
        a {
          text-decoration: none;
        }
        .download-tag {
          font-size: 16px;
          line-height: 2;
          color: $popup_download_text;
        }
      }
    }

    @media only screen and ($mobile-portrait) {
      &.md,
      &.sm {
        width: 90%;
      }
    }
  }
  &.preview-table-container {
    tr {
      th {
        font-family: Open Sans Semibold;
        font-size: 14px;
        line-height: 1.35;
        color: $col-number-blue;
        .help-text {
          display: flex;
          align-items: center;
          line-height: 1;
          .mandatory {
            font-family: Open Sans Semibold;
            font-size: 12px;
            line-height: 1.41;
            color: #8135d3;
            margin-left: 0;
          }
          .error-count {
            margin-top: 2px;
            margin-right: 4px;
            color: #cc4b4b;
          }
        }
        .alert {
          display: flex;
          margin-right: 25px;
        }
        .img-container {
          height: 18px;
          width: 18px;
        }
      }
      td {
        font-family: "Open Sans Regular";
        font-size: 12px;
        line-height: 1.67;
        color: $col-table-body-text;
        .img-alert-container {
          height: 24px;
          width: 24px;
        }
        .error-data {
          color: #ee3124;
        }
        .body-error {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.number-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  &.simulator-text {
    &.interactions {
      width: 100%;
      height: 40px;
      .input-container {
        height: 100%;
        width: 100%;
      }
    }
  }
  label {
    color: $color_secondary;
  }
  .input-container {
    display: flex;
    input {
      width: 100%;
      padding: 10px 5px;
      border: 1px solid $input_border_gray;
      border-radius: 4px;
      font-size: 12px;
    }
    input:focus,
    :active {
      border: unset !important;
      border-radius: 4px;
      outline: 1px solid $btn_blue !important;
    }
    &:hover {
      border: unset !important;
      border-radius: 4px;
      outline: 1px solid $btn_blue !important;
    }
  }
}

/** multigroupselect*/

.multigroupselect-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  label {
    color: $pure_black;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
  }
  .MuiOutlinedInput-root {
    border-radius: 8px;
    &.Mui-focused {
      border: 1px solid $btn_blue;
      img {
        filter: invert(43%) sepia(91%) saturate(671%) hue-rotate(156deg)
          brightness(96%) contrast(86%);
      }
    }
  }

  .MuiSelect-select:focus {
    border-radius: 0;
    background-color: transparent;
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    padding-top: 10px;
    padding-right: 32px;
    padding-bottom: 10px;
    padding-left: 9px;
    height: auto;
  }
  .MuiMenu-list {
    &::-webkit-scrollbar {
      width: 5px;
      pointer-events: none;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px $color_secondary;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $tile_border;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $menu_inactive;
    }
  }
  .group-multiselect-box {
    max-height: 101px;
    height: auto;
    overflow-y: overlay;
    overflow-x: hidden;
    width: 100%;
    border: 1px solid $dropdown_border;
    border-radius: 4px;
    &::-webkit-scrollbar {
      width: 5px;
      pointer-events: none;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px $color_secondary;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $tile_border;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $menu_inactive;
    }
  }

  .MuiOutlinedInput-input {
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 11px;
    color: rgba(0, 0, 0, 0.38);
  }

  .MuiOutlinedInput-root:hover {
    border-color: $btn_blue !important;
  }
}

.group-multiselect {
  width: 100%;
  border-radius: 8px;
  :hover {
    border-color: $color_secondary;
  }

  .multigroupselect-placeholder {
    padding-left: 5px;
    color: black;
    font-family: "Open Sans Regular";
    font-size: 14px;
  }

  .multigroupselect-chips {
    display: flex;
    flex-wrap: wrap;
  }

  .class-chip {
    margin: 2px 2px;
    width: fit-content;
    max-width: -webkit-fill-available;
  }
}

.multiselect-group-option {
  cursor: pointer;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  align-items: center !important;
  padding-left: 38px !important;
  font-size: $dropdownitem_font_size !important;
  color: $dropdownitem_color !important;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: transparent !important;
}
.MuiListItem-button:hover {
  text-decoration: none;
  background-color: transparent !important;
}

.multi-group.MuiListItem-button:hover {
  text-decoration: none;
  background-color: transparent;
}
.multi-group {
  /** for reference */

  padding-top: 15px !important;
  padding-bottom: 15px !important;
  align-items: center !important;
  font-size: $dropdownitem_font_size !important;
  color: $dropdownitem_color !important;
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: transparent !important;
  }
}
.heading-style {
  font-weight: bold !important;
  justify-content: space-between !important;
  font-size: $dropdownitem_font_size !important;
  color: $dropdown_heading_color !important;
  padding-right: 23px !important;
  font-family: "Open Sans Regular" !important;
}
.dropdown-checkbox {
  margin-right: 18px;
  width: 18px;
  height: 18px;
  color: $dropdown_checkbox_color;
}
.label-checkbox {
  width: calc(100% - 26px);
}
.dropdown-lbl {
  height: 19px;
  width: 19px;
}

.segment-class {
  padding-left: 28px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 23px;
  border-bottom: 1px solid $dropdown_border_color;
  color: $dropdown_segment_color;
  font-weight: 600;
  justify-content: space-between !important;
  display: flex;
  font-size: 14px;
}

/** end of css for multi group select */

.width100 {
  width: 100%;
}
/* page header*/
.page-header {
  justify-content: flex-start;
  padding-bottom: 5px;
  margin-left: 10px;

  .heading {
    text-align: left;

    p {
      font-family: "Open Sans Regular";
      font-size: $fontsize_h3;
      letter-spacing: 0;
      font-weight: 600;
      color: $pure_black;
      white-space: nowrap;
    }
  }
}
/*end of page header*/
.MuiAutocomplete-popper {
  .MuiPaper-rounded {
    box-shadow: 0px 3px 6px #00000033;
    .MuiAutocomplete-listbox {
      padding-top: 0;
      padding-bottom: 0;
      li {
        border-bottom: 1px solid $input_border_gray;
        &.MuiAutocomplete-option {
          padding-top: 16px;
          padding-bottom: 16px;
          padding-left: 16px;
          font-family: "Open Sans Regular";
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0px;
          color: $input_text_gray !important;
          .MuiCheckbox-root {
            margin-right: 6px;
            padding-right: 0;
          }
          &.Mui-focused {
            background-color: $dropdown_hover_gray;
          }
          &[aria-selected="true"] {
            background-color: white;
          }
        }
        path {
          fill: $checkbox_border_color;
        }
        .Mui-checked {
          path {
            fill: $btn_blue;
          }
        }
      }
      /* width */
      &::-webkit-scrollbar {
        width: 5px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $color_secondary;
        border-radius: 10px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $tile_border;
        border-radius: 10px;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $menu_inactive;
      }
    }
  }
}

.MuiPaper-root {
  box-shadow: 0px 3px 6px #00000033;
  border-radius: 4px;
  .MuiMenu-list {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $color_secondary;
    border-radius: 10px;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $tile_border;
    border-radius: 10px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $menu_inactive;
  }
  .MuiMenuItem-root.Mui-selected.multi-group {
    &[aria-selected="true"] {
      background-color: transparent;
    }
  }
}

.mandatory {
  color: $col-mandatory-text;
  font-family: Open Sans Semibold;
  font-size: 10px;
  line-height: 14px;
  margin-left: 6px;
  &.lbl-position {
    float: right;
    margin-top: 2%;
  }
}
.campaign-item {
  .dropdown-container {
    &:nth-of-type(1) {
      & > div {
        width: 100%;
      }
      .mandatory {
        width: 97%;
        text-align: right;
        display: inline-block;
      }
    }
  }
}

// date range component

.daterange-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .btn-container {
    border-top: 1px solid $checkbox-color;
  }

  .dropdown-input-field {
    height: 40px;
    width: 100%;
    border: 1px solid $dropdown_border;
    border-radius: 4px;

    .dropdown-button {
      display: flex;
      padding: 10px 3px 10px 10px;
      justify-content: space-between;
      align-items: center;
      label {
        font-size: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .arrow-up {
      transform: rotate(180deg);
    }
    &:active,
    &:focus {
      border: 1px solid $btn_blue;
    }
    &:hover {
      border: 1px solid $btn_blue;

      .dropdown-button img {
        filter: invert(43%) sepia(91%) saturate(671%) hue-rotate(156deg)
          brightness(96%) contrast(86%);
      }
    }
  }
  .dropdown-input-field.active {
    border: 1px solid $btn_blue;
  }
  .dropdown-options {
    width: 100%;
    position: absolute;
    z-index: 1;
    border: 1px solid $dropdown_border;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000033;
    border-radius: 4px;
    opacity: 1;
  }
  .dropdown-option-item {
    padding: 16px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    .tick-icon {
      background: icon("icon-green-tick", "svg");
      height: 24px;
      width: 24px;
    }
    label {
      color: $color_secondary;
    }
    border-bottom: 1px solid $dropdown_border;
  }
  .dropdown-option-item:hover {
    background: #f8f9fa 0% 0% no-repeat padding-box;
  }
}
.MuiAutocomplete-paper {
  border-radius: 0px !important;
}
.tick-icon {
  background: icon("icon-green-tick", "svg");
  height: 24px;
  width: 24px;
  margin-right: 16px;
}

.survey_txt_clr {
  color: #1792e5;
}
